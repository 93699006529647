import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'
import { DimensionOption, DimensionFilterState, DimensionCategories } from '../../../util/hooks/api/Filter/types'
import TextField from '@mui/material/TextField'
import Autocomplete, { AutocompleteRenderGroupParams } from '@mui/material/Autocomplete'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { BoldDownArrowIcon } from '../../icons/DownArrowIcon'
import { CustomFilterAction } from '../../../contexts/CustomFilterContext/state'

interface Props {
  readonly actions: CustomFilterAction
  filterIndex: number
  dimensionIndex: number
  state: DimensionFilterState
  stateIndex: number
  dimensionOptions: DimensionOption[]
  readonly onDimensionUnitChange: (
    newDimensionOption: DimensionOption,
    index: number,
    dimensionIndex: number,
    stateIndex: number,
  ) => void
}

export function DimensionNameSelect({
  actions,
  filterIndex,
  dimensionIndex,
  state,
  stateIndex,
  dimensionOptions,
  onDimensionUnitChange,
}: Props) {
  const dimensionOption = actions.getDimensionOption(state)
  const { dimensionCategory, name } = dimensionOption

  const [expandedAccordionGroup, setExpandedAccordionGroup] = useState<DimensionCategories>(dimensionCategory)
  const [isTextSearching, setIsTextSearching] = useState(false)
  const [isFocus, setIsFocus] = useState(false)
  const [isVisibleError, setIsVisibleError] = useState(false)

  React.useEffect(() => {
    setIsVisibleError(!isFocus && !name)
  }, [isFocus, name])

  // アイテムの選択完了時
  const handleChange = (_event: any, newDimensionOption: DimensionOption | null) => {
    if (!newDimensionOption) return
    onDimensionUnitChange(newDimensionOption, filterIndex, dimensionIndex, stateIndex)
    setExpandedAccordionGroup(newDimensionOption.dimensionCategory)
    setIsTextSearching(false)
  }

  // テキスト入力で検索開始時 OR Clearボタンでテキストクリア時
  const handleInputChange = (event: React.SyntheticEvent<{}>, inputValue: string) => {
    if (!event) return

    // 入力フォーカスが外れた場合の処理
    if (event?.type === 'blur') {
      setIsTextSearching(false)
      return
    }

    // テキスト入力で検索中はAccordion全開にする
    setIsTextSearching(!!inputValue)
  }

  return (
    <Autocomplete
      options={dimensionOptions}
      groupBy={(option: DimensionOption): DimensionCategories => option.dimensionCategory}
      getOptionLabel={(option: DimensionOption) => option.name}
      value={dimensionOption.id ? dimensionOption : null} // NOTE: 値が無いとMUIのエラーが起きるので、INITIAL_DIMENSION_OPTION の場合はnullを返す
      renderInput={(params) => (
        <TextField
          error={isVisibleError}
          onBlur={() => setIsFocus(false)}
          onFocus={() => setIsFocus(true)}
          {...params}
        />
      )}
      onChange={handleChange}
      onInputChange={handleInputChange}
      sx={AutocompleteSx}
      data-testid="filter-dimension-name"
      renderGroup={(params: AutocompleteRenderGroupParams) => [
        <StyledAccordion
          key={params.group}
          defaultExpanded={isTextSearching || expandedAccordionGroup === params.group || !state.id}
          disableGutters
          square
        >
          <StyledAccordionSummary expandIcon={<BoldDownArrowIcon size="24" />}>{params.group}</StyledAccordionSummary>
          <StyledAccordionDetails>{params.children}</StyledAccordionDetails>
        </StyledAccordion>,
      ]}
    />
  )
}

const AutocompleteSx = {
  // input form
  '& .MuiAutocomplete-inputRoot': {
    width: '380px',
    background: colors.white,
    color: colors.gray800,
    borderRadius: '2px',
    cursor: 'pointer',
    height: '30px',
    fontSize: '11.2px',
    padding: '0 7px',
  },
}

const StyledAccordion = styled(Accordion)`
  font-size: 12px;
  box-shadow: none;
  &:before {
    opacity: 0;
  }
`

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 2px 0 2px 16px;
  min-height: 0;
  border-bottom: 1px solid ${colors.gray250};
  background: ${colors.gray100};
  font-weight: bold;
  color: ${colors.contentBlue.blue7};
  & .MuiAccordionSummary-content {
    margin: 0;
  }
`

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
  background: ${colors.white};
  // mouse hover color & keyboard select color
  .MuiAutocomplete-option:hover,
  .MuiAutocomplete-option.Mui-focused {
    background-color: ${colors.contentBlue.blue1} !important;
  }
  // selected color
  .MuiAutocomplete-option[aria-selected='true'] {
    background-color: ${colors.contentBlue.blue2} !important;
  }
`
