import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'
import { Modal, ModalButtonWrapper } from '../../common/Modal'
import { AddButton, CancelButton, NormalButton } from '../../common/Button'
import {
  DimensionOption,
  CustomFilterState,
  DimensionFilterState,
  AggregateScopes,
} from '../../../util/hooks/api/Filter/types'
import { FilterItems } from './FilterItems'
import {
  CONDITION_TYPES,
  DEFAULT_DIMENSION_OPTIONS,
  DIMENSION_BETWEEN_TYPES,
  DIMENSION_EMPTY_TYPES,
} from '../../../util/hooks/api/Filter/constants'
import { useReportFilterSelects } from '../../../util/hooks/api/Filter/useReportFilterSelects'
import { ErrorBox } from '../../common/ErrorBox'
import { CustomFilterContext } from '../../../contexts/CustomFilterContext'
import { ProjectContext } from '../../../pages/ProjectRoot'
import { CustomFilterAction, CustomFilterModalState } from '../../../contexts/CustomFilterContext/state'

interface CustomFilterUseContextProps {
  readonly onReset?: () => void // リセット
  readonly onApply?: () => void // 適用
}

interface CustomFilterProps {
  readonly state: CustomFilterModalState
  readonly actions: CustomFilterAction
  readonly onReset?: () => void // リセット
  readonly onApply?: () => void // 適用
  readonly scopeOptions?: { value: AggregateScopes; label: string }[]
}

export function CustomFilterUseContext(props: CustomFilterUseContextProps) {
  const { state, actions } = React.useContext(CustomFilterContext)
  return CustomFilter({
    ...props,
    state: state,
    actions: actions,
  })
}

export function CustomFilter(props: CustomFilterProps) {
  const { state, actions, onReset, onApply, scopeOptions } = props

  const {
    state: { projectId },
  } = React.useContext(ProjectContext)

  const handleApply = () => {
    if (onApply) onApply()
    actions.onApply()
  }

  const handleReset = () => {
    if (onReset) onReset()
    actions.onReset()
  }

  const isApplyButtonDisabled = () => {
    return state.tempCustomFilterState.some((filter: CustomFilterState) => {
      return filter.dimensions.some((dimension) => {
        return dimension.states.some((state: DimensionFilterState) => {
          if (!state.id) return true

          const { conditionType } = actions.getDimensionOption(state)

          // valueを入力する必要がない一致条件の場合は活性状態で表示する
          if (
            conditionType === CONDITION_TYPES.CHOICE ||
            conditionType === CONDITION_TYPES.BOOL ||
            DIMENSION_EMPTY_TYPES.includes(state.condition)
          )
            return false

          if (DIMENSION_BETWEEN_TYPES.includes(state.condition)) {
            return !state.fromValue || !state.toValue
          }
          return !state.value
        })
      })
    })
  }

  const {
    data: filterSelectsData,
    isError: isFilterSelectsError,
    error: filterSelectsError,
    isLoading: filterSelectsLoading,
  } = useReportFilterSelects({ projectId: projectId.toString() })

  const dimensionOptions: DimensionOption[] = [
    ...DEFAULT_DIMENSION_OPTIONS,
    ...(filterSelectsData
      ? [...filterSelectsData.goals, ...filterSelectsData.customDimensions, ...filterSelectsData.dataImportFields]
      : []),
  ]

  return (
    <Modal
      isOpen={state.openedFilter}
      onClose={actions.onCancel}
      title="フィルタ条件"
      width={1150}
      isLoading={filterSelectsLoading}
    >
      {isFilterSelectsError ? (
        <ErrorBoxWrapper>
          <ErrorBox>
            {typeof filterSelectsError === 'string'
              ? filterSelectsError
              : 'カスタムディメンションの取得に失敗しました。'}
          </ErrorBox>
        </ErrorBoxWrapper>
      ) : (
        <>
          <Box>
            <OptionBox>
              <div style={{ margin: '1rem auto 1rem 2rem' }}>
                <AddButton onClick={actions.onAdd}>フィルタを追加</AddButton>
              </div>
              {state.tempCustomFilterState.length > 0 && (
                <CancelButton onClick={handleReset}>フィルタをリセット</CancelButton>
              )}
            </OptionBox>
            <FilterItems
              state={state}
              actions={actions}
              dimensionOptions={dimensionOptions}
              scopeOptions={scopeOptions}
            />
          </Box>

          <ModalButtonWrapper>
            <CancelButton onClick={actions.onCancel}>キャンセル</CancelButton>
            <NormalButton onClick={handleApply} disabled={isApplyButtonDisabled() || actions.hasInputError()}>
              フィルタを適用する
            </NormalButton>
          </ModalButtonWrapper>
        </>
      )}
    </Modal>
  )
}

const Box = styled.div`
  min-width: 100%;
  max-height: 420px;
  border: 1px solid ${colors.gray400};
  overflow-y: auto;
  margin-bottom: 24px;
`

const OptionBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.5rem;
  padding-right: 1.5rem;
`

const ErrorBoxWrapper = styled.div`
  margin: 20px;
  width: calc(100% - 40px);
`
