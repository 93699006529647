import * as React from 'react'
import styled from 'styled-components'
import { GlobalContext } from '../../GlobalState'
import { colors, layout, HEADER_HEIGHT, HEADER_NEWS_HEIGHT, REPORT_HEADER_HEIGHT } from '../../styleConstants'
import { DeviceType } from '../../util/hooks/useDeviceType'
import { isContentReportPage, isPageCapturePage } from '../../util/locations'
import { Navigation } from '../navigation'
import { Header } from '../common/Header'
import { BreadcrumbsItemType } from '../breadcrumbs/Breadcrumbs'
import { useSelector } from '../../redux/Store'
import { navigationState } from '../../redux/navigationSlice'
import { useLocation } from '@gatsbyjs/reach-router'
import { Footer } from '../common/Footer'

interface Props {
  readonly children?: React.ReactNode
  readonly headerTitle: string
  readonly optionHidden?: boolean
  readonly goalHidden?: boolean
  readonly filterButtonHidden?: boolean
  readonly baseUrl?: string
  readonly contentRef?: React.RefObject<HTMLElement>
  readonly adminLayout?: boolean
  readonly accountLayout?: boolean
  readonly filterApply?: boolean
  readonly url?: string
  readonly urlTitle?: string
  readonly selectedDevice?: DeviceType
  readonly isReportPage?: boolean
  readonly toolbar?: React.ReactNode
  readonly breadcrumbsItems?: BreadcrumbsItemType[]
  readonly footerHidden?: boolean
  readonly footerAreaPadding?: number
  readonly onChangeDevice?: (deviceType: DeviceType) => void
  readonly onDateRangeApply?: () => void
  readonly onGoalChange?: () => void
  readonly onScopeTypeChange?: () => void
}

export function PageLayout(props: Props) {
  const { opened } = useSelector(navigationState)
  const { pathname } = useLocation()

  const isContentReport = isContentReportPage(pathname)
  const isPageCapture = isPageCapturePage(pathname)

  const {
    state: {
      newsState: { isVisible: isVisibleNews },
    },
  } = React.useContext(GlobalContext)

  const {
    children,
    headerTitle,
    optionHidden = false,
    goalHidden = false,
    baseUrl = '/',
    contentRef,
    adminLayout = false,
    accountLayout = false,
    url,
    urlTitle,
    isReportPage = false,
    toolbar,
    breadcrumbsItems,
    footerHidden = false,
    footerAreaPadding,
    onDateRangeApply,
    onGoalChange,
    onScopeTypeChange,
  } = props

  return (
    <Container>
      <Navigation
        baseUrl={baseUrl}
        adminLayout={adminLayout}
        accountLayout={accountLayout}
        isReportPage={isReportPage}
      />
      {isContentReport || isPageCapture ? (
        <ReportDummyNav opened={opened} />
      ) : (
        <DummyNav opened={opened} adminLayout={adminLayout} />
      )}
      <Box opened={opened}>
        <Header
          title={headerTitle}
          optionHidden={optionHidden}
          goalHidden={goalHidden}
          adminLayout={adminLayout}
          isContentReport={isContentReport}
          isPageCapture={isPageCapture}
          url={url}
          urlTitle={urlTitle}
          onDateRangeApply={onDateRangeApply}
          breadcrumbsItems={breadcrumbsItems}
          onGoalChange={onGoalChange}
          onScopeTypeChange={onScopeTypeChange}
        />

        {toolbar && toolbar}
        <Main
          adminLayout={adminLayout}
          isContentReport={isContentReport}
          footerAreaPadding={footerAreaPadding}
          hasToolbar={!!toolbar}
          isVisibleNews={isVisibleNews}
          ref={contentRef}
        >
          {children}
          {!isContentReport && !footerHidden && <Footer />}
        </Main>
      </Box>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

const Box = styled.div<{ opened: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  ${({ opened }) =>
    !opened
      ? `width: calc(100% - ${layout.navigationCloseWidth}); margin-left: ${layout.navigationCloseWidth};`
      : `width: calc(100% - ${layout.navigationWidth}); margin-left: ${layout.navigationWidth};`}
`

// ナビのアニメーションで背景が一瞬見えるため、コンテンツエリアと同じ色のボックスを出す
const DummyNav = styled.div<{ opened: boolean; adminLayout: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  &::before {
    display: block;
    content: '';
    height: 80px;
    ${({ opened }) => `width: ${opened ? layout.navigationWidth : layout.navigationCloseWidth}`};
    ${({ adminLayout }) => `background-color: ${adminLayout ? colors.orange : colors.headerBg}`};
  }
  &::after {
    display: block;
    content: '';
    height: calc(100% - 80px);
    ${({ opened }) => `width: ${opened ? layout.navigationWidth : layout.navigationCloseWidth}`};
    ${({ adminLayout }) => `background-color: ${adminLayout ? colors.lightSalmon : colors.bg}`};
  }
`

const ReportDummyNav = styled.div<{ opened: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${colors.blue};
  &::before {
    display: block;
    content: '';
    height: 80px;
    background-color: ${colors.headerBg};
    ${({ opened }) => `width: ${opened ? layout.navigationWidth : layout.navigationCloseWidth}`};
  }
  &::after {
    display: block;
    content: '';
    height: calc(100% - 128px);
    background-color: ${colors.bg};
    ${({ opened }) => `width: ${opened ? layout.navigationWidth : layout.navigationCloseWidth}`};
  }
`
const _calcHeaderHeight = (height: number, isVisibleNews: boolean): string => {
  // お知らせの有無を考慮してヘッダーの高さを算出する
  return (isVisibleNews ? height + HEADER_NEWS_HEIGHT : height) + 'px'
}

const Main = styled.main<{
  adminLayout: boolean
  hasToolbar: boolean
  isContentReport: boolean
  footerAreaPadding?: number
  isVisibleNews: boolean
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ hasToolbar, isContentReport, footerAreaPadding, isVisibleNews }) => {
    let hh = '0px' // ヘッダーの高さ
    if (footerAreaPadding !== undefined) {
      hh = _calcHeaderHeight(hasToolbar ? REPORT_HEADER_HEIGHT : HEADER_HEIGHT, isVisibleNews)
      return `calc(100vh - calc(${footerAreaPadding}px) - calc(${hh}))`
    }
    // コンテンツレポートは情報量が多いため、フッターは非表示
    if (isContentReport) {
      hh = _calcHeaderHeight(REPORT_HEADER_HEIGHT, isVisibleNews)
      return `calc(100vh - calc(${hh}))`
    }
    if (hasToolbar) {
      hh = _calcHeaderHeight(REPORT_HEADER_HEIGHT, isVisibleNews)
      return `calc(100vh - calc(${hh}) - calc(${layout.footerHeight}))`
    }
    hh = _calcHeaderHeight(HEADER_HEIGHT, isVisibleNews)
    return `calc(100vh - calc(${hh}) - calc(${layout.footerHeight}))`
  }};
  background-color: ${({ adminLayout }) => (adminLayout ? colors.lightSalmon : colors.bg)};
  overflow-x: auto;
  overflow-y: auto;
`
