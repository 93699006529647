import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'
import {
  DimensionOption,
  CustomFilterState,
  DimensionFilterState,
  DimensionKeys,
} from '../../../util/hooks/api/Filter/types'
import { DimensionNameSelect } from './DimensionNameSelect'
import { DimensionAddDeleteButton } from './DimensionAddDeleteButton'
import { DimensionItemValues } from './DimensionItemValues'
import { DimensionItemMatchType } from './DimensionItemMatchType'
import { DimensionItemPageTarget } from './DimensionItemPageTarget'
import { DIMENSION_PAGE_TARGET_COLUMN_KEY_TYPES } from '../../../util/hooks/api/Filter/constants'
import { CustomFilterAction } from '../../../contexts/CustomFilterContext/state'

interface Props {
  readonly actions: CustomFilterAction
  filter: CustomFilterState
  filterIndex: number
  dimensionOptions: DimensionOption[]
  dimension: {
    readonly states: DimensionFilterState[]
  }
  dimensionIndex: number
  state: DimensionFilterState
  stateIndex: number
}

export function DimensionItem(props: Props) {
  const { actions, filter, filterIndex, dimensionOptions, dimension, dimensionIndex, state, stateIndex } = props

  // ORが並んでいる場合、一番最後の要素だけがANDを押せる
  const andOff: boolean = !(dimension.states.length - 1 === stateIndex)

  return (
    <div>
      {dimensionIndex !== 0 && stateIndex === 0 && (
        <BorderBox style={{ margin: '1rem 0' }}>
          <div style={{ color: `${colors.gray500}` }}>AND</div>
          <Border />
        </BorderBox>
      )}
      <Dimension>
        <DimensionRow>
          <DimensionNameSelect
            actions={actions}
            filterIndex={filterIndex}
            dimensionOptions={dimensionOptions}
            dimensionIndex={dimensionIndex}
            state={state}
            stateIndex={stateIndex}
            onDimensionUnitChange={actions.onDimensionUnitChange}
          />
          {DIMENSION_PAGE_TARGET_COLUMN_KEY_TYPES.includes(state.id as DimensionKeys) && (
            <DimensionItemPageTarget
              state={state}
              onDimensionPageTargetColumnChange={actions.onDimensionPageTargetColumnChange}
              filterIndex={filterIndex}
              dimensionIndex={dimensionIndex}
              stateIndex={stateIndex}
            />
          )}
          <DimensionItemMatchType
            state={state}
            actions={actions}
            filterIndex={filterIndex}
            dimensionIndex={dimensionIndex}
            stateIndex={stateIndex}
          />
          <DimensionItemValues
            state={state}
            actions={actions}
            filterIndex={filterIndex}
            dimensionIndex={dimensionIndex}
            stateIndex={stateIndex}
          />
          <DimensionAddDeleteButton
            filterIndex={filterIndex}
            dimensionIndex={dimensionIndex}
            stateIndex={stateIndex}
            minusOff={filter.dimensions.length === 1 && dimension.states.length === 1} // 要素がない場合削除できない
            andOff={andOff}
            onMinus={actions.onMinus}
            onOr={actions.onOr}
            onAnd={actions.onAnd}
          />
        </DimensionRow>
      </Dimension>
      {andOff && (
        <BorderBox>
          <div style={{ color: `${colors.gray400}` }}>OR</div>
        </BorderBox>
      )}
    </div>
  )
}

const Dimension = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const DimensionRow = styled.div`
  display: flex;
  align-items: flex-start;
`

const BorderBox = styled.div`
  display: flex;
  align-items: center;
  height: 15px;
  margin: 0.5rem 0;
`

const Border = styled.div`
  height: 2px;
  background-color: ${colors.gray300};
  border: none;
  width: 100%;
  margin: 0 1rem;
`
